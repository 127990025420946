/* eslint-disable indent */
import React, { useState, useContext, useEffect } from 'react';
import Link from 'next/link';
import { toast } from 'react-toastify';

import { ALLOW_PRODUCT_COMPARE } from '../constants/verificationConst';
import notificationTypes from '../constants/modalNotificationConst';
import * as WEBSITES from '../constants/website';

import getStrainDetails from '../helpers/getStrainDetails';
import applyDiscount from '../helpers/applyDiscount';
import {
  getProductAttributeValue,
  calculateProductAttributePerDoller,
  getThcPerDollarAttrbutes
} from '../helpers/productAttributeHelper';

import { useDeliveryDetails } from '../hooks/app';
import { useModifyCart } from '../hooks/cart';

import appContext from '../Context/appContext';
// import appConfig from '../appConfig';

import AddToCartWidget from './DTC/drinkcann/AddToCartWidget';
import TerpenesProfile from './DTC/grassdoor/TerpenesProfile';
import BuyXGetYOffersList from './BuyXGetYOffersList';
import DisplayMessage from './Compare/DisplayMessage';
import DiscountInPercent from './DiscountInPercent';
import DangerousHTML from './DangerousHTML';
import DeliveryNote from './DeliveryNote';
import SeeMore from './DTC/zipps/SeeMore';
import BundlePrice from './BundlePrice';
import Prop65 from './Prop65';
import Price from './Price';
import { isWishlistApplicable } from '../constants/feature';
import HappyHours from './HappyHours';
import useSegment from '../hooks/segment';
import { isNewHomePageApplicable } from '../constants/feature';
import FavouritesIcon from './DTC/grassdoor/FavouritesIcon';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotificationOverlap, pushOnTopIfNotPresent, popStack, popNotification, increaseURLstack } from '../redux/slices/modalSlice';
import { setUrlBeforeCompare } from '../redux/slices/userSlice';
import { addProductToCompare, removeProductFromCompare } from '../redux/slices/compareSlice';
import { useConfigData } from '../Context/ConfigProvider';
import InlineLoader from './InlineLoader';

const ProductContent = ({
  showTag,
  page,
  productDetails,
  openInPopup,
  DTCproduct,
  title,
  isFeaturedProduct,
}) => {
  const {
    product_attributes: productAttributes = [],
    domain_specific_attributes: domainSpecificAttributes = [],
    bundle_id: bundleId = 0,
    product_details_id: productDetailsId = 0,
    is_sold_out: isSoldOut = false,
    is_disabled: isDisabled = false,
    is_pre_deal_available: isPreDealAvailable = false,
    product_max_quantity: productMaxQuantity = 0,
    is_deal_available: isDealAvailable = false,
    bulk_base_price: bulkBasePrice = 0,
    price_without_deal: priceWithoutDeal = 0,
    product_strain_type_name: strainName = '',
    brands = [],
    category_name: categoryName = '',
    bundle_name: bundleName = '',
    product_name: productName = '',
    limit_exceeded: limitExceeded = false,
    bulk_prices: bulkPrices = [],
    product_currency: productCurrency = '$',
    original_price: bundleOriginalPrice = 0,
    bundle_selling_price: bundleSellingPrice = 0,
    product_unit: productUnit = '',
    product_weight: productWeight = 0,
    price = 0,
    deal_price_type: dealPriceType = '',
    products = [],
    offer_product: offerProduct = [],
    product_description: productDescription = '',
    description: bundleDescription = '',
    master_product_id: masterProductId = 0,
    terpenes = [],
    showBestSeller,
    isMostPopularProduct,
    fresh_drop: freshDrop
  } = productDetails || {};
  const appConfig = useConfigData();
  // const productAttributes = productDetails.product_attributes ? productDetails.product_attributes : [];
  // const domainSpecificAttributes = productDetails.domain_specific_attributes ? productDetails.domain_specific_attributes : [];
  // productDetails.product_attributes = [...productAttributes, ...domainSpecificAttributes];
  const combinedAttributes = [...productAttributes, ...domainSpecificAttributes];

  const thcPerDollarAttributes = getThcPerDollarAttrbutes(combinedAttributes, appConfig.HIDE_PRODUCT_PROPERTIES);

  const { addToCart: callAddToCart, updateCartItem: callUpdateCartItem, deleteCartItem } = useModifyCart();

  const { data: { unified_menu: unifiedMenu, happyHours, isWalletCreditWithPurchaseApplicable } = {} } = useDeliveryDetails();
  const { is_enable: is_happy_hours_enable } = happyHours || {};

  // const {
  //   state: {
  //     // user: { isLoggedIn, couponData },
  //     // cart: { cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList },
  //     // compare
  //   },
  //   dispatch
  // } = useContext(appContext);

  const compare = useSelector(state => state.compare)
  const [showLoader, setShowLoader] = useState(false)
  const { isLoggedIn, couponData } = useSelector(state => state.user)
  const { cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList } = useSelector(state => state.cart) || {}
  const stackURL = useSelector(state => state.modal.stackURL)
  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)
  const dispatchRedux = useDispatch()

  const { trackEvent } = useSegment();

  const [animating, setAnimating] = useState(false);

  const updatingQuantity = updatingProductsList.includes(bundleId || productDetailsId);

  const currentProductAdded = cartItems.find(item => {
    return item.bundle_id === bundleId || item.product_details_id === productDetailsId;
  });

  const { quantity: quantityInCart = 0, is_pre_deal_available: isPreDealPresentInCart } = currentProductAdded || {};

  const [quantity, setQuantity] = useState(quantityInCart || 0)
  const isMaxedOutInCart = Number(quantityInCart) >= Number(productMaxQuantity || 0);
  useEffect(() => {
    if (['bulkQuantityPopup'].includes(notificationsOverlap?.[0]?.type)) {
      setShowLoader && setShowLoader(false)
    }
  }, [notificationsOverlap])
  const addToCart = async isImmediate => {
    setShowLoader(true)
    callAddToCart({
      product: productDetails,
      list: page,
      hideSuccess: true,
      // scheduleType: 0,
      immediate: isImmediate,
      isMostPopularProduct,
      type: 'pdp',
      isFeaturedProduct
    });
  };
  useEffect(() => {
    if (!showLoader) setQuantity(currentProductAdded && currentProductAdded.quantity || 0)
  }, [currentProductAdded, showLoader])
  const handleCompareCheck = () => {
    const tempDetails = { ...productDetails };
    delete tempDetails?.recommended_products;
    if (!tempDetails.master_product_id || !tempDetails) {
      toast.error('Invalid Product');
    } else if (compare && compare.productList.some(e => e === tempDetails.master_product_id)) {
      toast.info(`${tempDetails.product_name} is removed from compare`);

      // dispatch({
      //   type: 'removeProductFromCompare',
      //   payload: tempDetails
      // });
      dispatchRedux(removeProductFromCompare(tempDetails))
    } else {
      if (compare.productList.length > 7) {
        toast.error('Compare limit exceeded');
        return;
      }
      trackEvent('Product added to compare', { product: tempDetails, source: 'Product details page' });
      // dispatch({
      //   type: 'addProductToCompare',
      //   payload: tempDetails
      // });
      dispatchRedux(addProductToCompare(tempDetails))

      toast.dismiss();
      toast.info(<DisplayMessage />);
    }
  };

  const openCompareClick = e => {
    e.preventDefault();
    const url = '/compare';
    dispatchRedux(notificationsOverlap.length ? pushNotificationOverlap({ type: notificationTypes.comparePopup, data: { source: 'Product details page' } }) : pushOnTopIfNotPresent({ type: notificationTypes.comparePopup, data: { source: 'Product details page' } }))
    dispatchRedux(increaseURLstack());
    // dispatch({ type: 'setUrlBeforeCompare', payload: { urlBeforeCompare: window.location.href } });
    dispatchRedux(setUrlBeforeCompare({ urlBeforeCompare: window.location.href }))
    window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
  };

  const updateCartItem = async () => {
    if (isLoggedIn) {
      setShowLoader && setShowLoader(true)
    }
    else {
      setShowLoader && setShowLoader(true)
      let tO = setTimeout(() => {

        setShowLoader && setShowLoader(false)
        clearTimeout(tO)
      }, 900)
    } if (quantityInCart == 1) {
      let walletWithPurchaseProps = null;

      if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
        walletWithPurchaseProps = {
          currentProductDetails: { ...productDetails, quantity: 1 },
          cartSubtotal: amount,
          dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
          requestedProductQuantity: 0,
          onRemove: () =>
            deleteCartItem({
              product: { ...productDetails },
              type: 'pdp',
              isFeaturedProduct,
              isMostPopularProduct
            })
        };
      }

      deleteCartItem({
        product: { ...productDetails },
        type: 'pdp',
        isFeaturedProduct,
        isMostPopularProduct,
        walletWithPurchaseProps
      });
    } else {
      let walletWithPurchaseProps = null;

      if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
        walletWithPurchaseProps = {
          currentProductDetails: { ...productDetails, quantity: quantityInCart },
          cartSubtotal: amount,
          dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
          requestedProductQuantity: quantityInCart - 1,
          onRemove: () =>
            callUpdateCartItem({
              product: { ...productDetails, quantity: quantityInCart - 1 },
              type: 'pdp',
              isFeaturedProduct,
              isMostPopularProduct
            })
        };
      }

      callUpdateCartItem({
        product: { ...productDetails, quantity: quantityInCart - 1 },
        type: 'pdp',
        isFeaturedProduct,
        isMostPopularProduct,
        walletWithPurchaseProps
      });
    }
  };

  const openBrandPage = e => {
    e.preventDefault();
    dispatchRedux(popNotification())

    for (let i = 0; i < stackURL; i++) {
      window.history.back();
    }
    dispatchRedux(popStack())
    window.location.href = `/brands/${brands[0]?.slug || ''}`;
  };

  const animateButtons = () => {
    if (!animating) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
      }, 500);
    }
  };

  const renderMultiPrice = () => {
    const weight = productUnit === '1/8 oz' || productUnit === '1/2 oz' ? `${productUnit}` : `${productWeight}${productUnit}`;

    return (
      <div className={`pricing-table my-2 ${DTCproduct ? '' : ''}`}>
        {/* commented code is for future */}
        {/* <div className="d-flex justify-content-between align-items-center mb-3 ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="stars-wrapper">
             {[1,2,3,4,5].map(count => <i  className={count <= 3?"icon-star-filled ":"icon-star-outline"}/>)}
             </div>
            <span className="pl-3">10 Reviews</span>
          </div>
          <div className="align-items-center d-flex rate-right mt-2 mt-lg-0">
            <span className="circle mr-2" />
            <span> 100% would recommend</span>
          </div>
        </div> */}

        <div className="semi-bold pb-2 cat-name">Volume Pricing Table</div>

        <div className="">
          <table className="table">
            <thead>
              <th className="grey-color ">Units</th>
              <th className="grey-color ">Weight</th>
              <th className="grey-color text-right ">Price/Unit</th>
            </thead>

            <tbody>
              {bulkPrices.map((item, index) => (
                <tr>
                  <td className=" semi-bold">
                    {item.quantity}
                    {index === bulkPrices.length - 1 ? '+' : ''}
                  </td>

                  <td className="">{`${item.quantity} x ${weight}`}</td>

                  <td className="text-right">
                    {priceWithoutDeal.toFixed(2) !==
                      parseFloat(applyDiscount(productDetails, item.price, couponData)).toFixed(2) ? (
                      <span className="strike-text text-primary mr-1">{`$${priceWithoutDeal.toFixed(2)}`}</span>
                    ) : null}

                    <span className="text-primary">
                      {`$${parseFloat(applyDiscount(productDetails, item.price, couponData)).toFixed(2)}`}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const productPresent = !isSoldOut && !isDisabled;

  const renderProductStatus = () => {
    if (!productPresent) {
      return <div className="soldout-limit">Sold out</div>;
    }

    if (limitExceeded) {
      return <div className="soldout-limit">Limit Exceeded</div>;
    }

    return null;
  };


  const originalPrice = isDealAvailable || isPreDealAvailable ? priceWithoutDeal : bulkBasePrice;

  const classnameDTC1 = DTCproduct ? 'd-flex justify-content-between' : 'a-item';
  const classnameDTC2 = DTCproduct ? 'grey-color fs-12' : '';

  const primaryBrandDetails = brands?.[0] || null;
  const strainDetails = getStrainDetails(strainName || '');

  useEffect(() => {
    if (!updatingQuantity) setShowLoader(false)
  }, [updatingQuantity])
  return (
    <div className={`info ${!DTCproduct ? 'py-2' : ''}`}>
      {DTCproduct ? <DeliveryNote /> : null}

      <div>
        {DTCproduct ? (
          <>
            {renderProductStatus()}

            {primaryBrandDetails?.slug && primaryBrandDetails?.name ? (
              <div className="mb-1 cat-name ">
                <Link href="/brands/[slug]" as={`/brands/${primaryBrandDetails.slug}`}>
                  <a
                    href={`/brands/${primaryBrandDetails.slug}`}
                    onClick={openInPopup ? openBrandPage : null}
                    className="link-color"
                  >
                    {primaryBrandDetails.name}
                  </a>
                </Link>
              </div>
            ) : null}
            {/* {freshDrop ? <div className="fresh-drop"><span>NEW</span></div> : null} */}

            <div>
              {categoryName ? <h6 className="grey-color cat-name">{categoryName}</h6> : null}

              <h1 className="product-name" test-id="#pdp_product_name">
                {bundleName || productName || ''}
              </h1>

              {isDealAvailable ? (
                <div className="sale d-inline-block">
                  <DiscountInPercent
                    productFinalPrice={price}
                    priceWithoutDeal={priceWithoutDeal}
                    dealPriceType={dealPriceType}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : isNewHomePageApplicable ? (
          <div className="pdp-bottom-info">
            <h1 className="product-name mb-0 mt-3 bold " test-id="#pdp_product_name">
              {bundleName || productName || ''}{' '}

              {' '}
              {!appConfig?.hideProductWeight && !bundleId ? (
                <span><span className="yellow-divider">{" | "}</span>
                  {productUnit === '1/8 oz' || productUnit === '1/2 oz' ? productUnit : `${productWeight}${productUnit}`}
                </span>
              ) : null}
            </h1>
            {primaryBrandDetails?.slug && primaryBrandDetails?.name && !appConfig.hideBrandPage ? (
              <div className="link-wrap">
                <Link href="/brands/[slug]" as={`/brands/${primaryBrandDetails.slug}`}>
                  <a
                    href={`/brands/${primaryBrandDetails.slug}`}
                    onClick={openInPopup ? openBrandPage : null}
                    className="grey-color gd-link"
                  >
                    View more from
                    {' '}
                    <span>{primaryBrandDetails.name}</span>
                    &nbsp;&nbsp;
                    <span>&#8594;</span>
                  </a>
                </Link>
              </div>
            ) : null}
            {strainName ? (
              <div className={`strain-name ${strainDetails.colour}`}>
                <span className={`${strainDetails.icon}`} />
                <span>{` ${strainName}`}</span>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            {strainName ? (
              <div className={`strain-name ${strainDetails.colour}`}>
                <span className={`${strainDetails.icon}`} />
                <span>{` ${strainName}`}</span>
              </div>
            ) : null}

            <h1 className="product-name mb-0 mt-3 bold" test-id="#pdp_product_name">
              {bundleName || productName || ''}
            </h1>

            {renderProductStatus()}

            {primaryBrandDetails?.slug && primaryBrandDetails?.name ? (
              <div className="mb-1 mt-2">
                <Link href="/brands/[slug]" as={`/brands/${primaryBrandDetails.slug}`}>
                  <a
                    href={`/brands/${primaryBrandDetails.slug}`}
                    onClick={openInPopup ? openBrandPage : null}
                    className="grey-color gd-link"
                  >
                    {primaryBrandDetails.name}
                  </a>
                </Link>
              </div>
            ) : null}
          </div>
        )}

        {DTCproduct ? (
          <>
            {bulkPrices.length ? (
              renderMultiPrice()
            ) : (
              <div className="d-flex align-items-baseline price-container drinkcannn-price">
                {bundleId ? (
                  <BundlePrice
                    className="price text-primary"
                    currency={productCurrency}
                    orignalPrice={bundleOriginalPrice}
                    price={bundleSellingPrice}
                    otherAttributes={{ 'test-id': `#pdp_bundle_price_${bundleId}` }}
                  />
                ) : (
                  <Price
                    productDetails={productDetails}
                    priceClassName="text-primary"
                    orignalPrice={originalPrice}
                    otherAttributes={{ 'test-id': `#pdp_product_price_${productDetails.product_id}` }}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <div className={isNewHomePageApplicable ? 'price-chemical-wrap' : ''}>
            <>
              <div className={`d-flex align-items-center ${isNewHomePageApplicable ? 'price-wrap' : ''}`}>
                <div className="price-containers">
                  {bundleId ? (
                    <BundlePrice
                      className="price"
                      currency={productCurrency}
                      orignalPrice={bundleOriginalPrice}
                      price={bundleSellingPrice}
                      otherAttributes={{ 'test-id': `#pdp_bundle_price_${bundleId}` }}
                    />
                  ) : (
                    <Price
                      productDetails={productDetails}
                      priceClassName=" "
                      orignalPrice={originalPrice}
                      otherAttributes={{ 'test-id': `#pdp_product_price_${productDetails.product_id}` }}
                    />
                  )}
                </div>

                <div className="d-flex align-items-center">
                  {!appConfig?.hideProductWeight && !bundleId && !isNewHomePageApplicable ? (
                    <div className="attribute-grid">
                      <span className="html-dot"> &#8226;</span>
                      <div className="a-item gg">
                        <div className="bold">
                          {productUnit === '1/8 oz' || productUnit === '1/2 oz' ? productUnit : `${productWeight}${productUnit}`}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {isDealAvailable ? (
                    <div className="sale ">
                      <DiscountInPercent
                        productFinalPrice={price}
                        priceWithoutDeal={priceWithoutDeal}
                        dealPriceType={dealPriceType}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="">{bulkPrices.length ? renderMultiPrice(productDetails) : null}</div>
            </>
            {/* {(title === 'Most Popular' || showBestSeller == 1) && showRecommendedOrPopularProductsCat && <div className="best-seller mb-2 mt-1">Popular</div>} */}

            <div className={`chemical-content-wrap ${combinedAttributes.length ? '' : 'd-none'}`}>
              {isNewHomePageApplicable ? <h6 className="chemical-heading">Chemical content</h6> : null}
              {combinedAttributes?.length ? (
                <div className={isNewHomePageApplicable ? 'mt-0' : 'mt-3'}>
                  <div className="d-flex flex-wrap ">
                    {combinedAttributes.map(item => (
                      <div className="units-table ">
                        <div>{`${item.name}:`}</div>

                        <div className="cont-value">{getProductAttributeValue(item)}</div>
                      </div>
                    ))}

                    {thcPerDollarAttributes.map(item => (
                      <div className="units-table  ">
                        <div>{`${item.name}/$:`}</div>
                        <div className="cont-value">
                          {calculateProductAttributePerDoller(item, price, productWeight, productUnit)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        {DTCproduct ? (
          <>
            {!appConfig?.hideProductWeight || !bundleId || !!combinedAttributes?.length ? (
              <div className="d-flex half-rem-mt hide-for-rightratio">
                <h6 className="maven-white-color">
                  <i className="icon-weight mr-2" />

                  {productUnit === '1/8 oz' || productUnit === '1/2 oz' ? productUnit : `${productWeight}${productUnit}`}
                </h6>
              </div>
            ) : null}

            {combinedAttributes?.length ? (
              <div className="one-rem-mb  grid-col">
                {!appConfig.HIDE_PRODUCT_WEIGHT ? (
                  <div className={`weight-handle-parent ${classnameDTC1}`}>
                    <div className={`weight-handle ${classnameDTC2}`}>Weight</div>

                    <div className="">
                      {productUnit === '1/8 oz' || productUnit === '1/2 oz' ? productUnit : `${productWeight}${productUnit}`}
                    </div>
                  </div>
                ) : null}
                {combinedAttributes.map(item => (
                  <div className={classnameDTC1}>
                    <div className={classnameDTC2}>
                      {`${item.name}:`}
                      &nbsp;
                    </div>

                    <div className="">{getProductAttributeValue(item)}</div>
                  </div>
                ))}

                {thcPerDollarAttributes.map(item => (
                  <div className={classnameDTC1}>
                    <div className={classnameDTC2}>{`${item.name}/dollar:`}</div>

                    <div className="">{calculateProductAttributePerDoller(item, price, productWeight, productUnit)}</div>
                  </div>
                ))}

                {strainName ? (
                  <div className={`${classnameDTC1} strain-parent`}>
                    <div className={classnameDTC2}>Strain</div>
                    <div className="">{strainName}</div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}

        {products.length ? (
          <div className="grid-col one-rem-mb">
            <div className="grey-color bold text-uppercase half-rem-mb">
              <i className="icon-box mr-1" />

              <span>Products</span>
            </div>

            {products.map(item => (
              <div className="d-flex justify-content-between mb-1">
                <div className="d-flex">
                  <strong className="b-count d-inline-block">{item.quantity}</strong>

                  <i className="grey-color icon-close mx-2 " />

                  <div>{item.product_name}</div>
                </div>

                <div className="text-primary pl-1">{`${item.currency}${item.price}`}</div>
              </div>
            ))}
          </div>
        ) : null}

        {offerProduct ? <BuyXGetYOffersList data={productDetails} /> : null}
        {is_happy_hours_enable && (
          <div className="prodpage-hh">
            <HappyHours />
          </div>
        )}

        <div className={`add-to-cart-holder ${!DTCproduct ? 'mt-4' : ''}`}>
          {[
            WEBSITES.KOAN,
            WEBSITES.DRINKCANN,
            WEBSITES.WUNDER,
            WEBSITES.BEED,
            WEBSITES.CANNADIPSTHC,
            WEBSITES.POTLI,
            WEBSITES.ALTLIFE,
            WEBSITES.HELLOAGAIN,
            WEBSITES.VETCBD,
            // WEBSITES.HUMBOLDTFAMILYFARMS,
            WEBSITES.MAISONBLOOM,
            WEBSITES.BOASTCANNABIS,
            WEBSITES.RIGHTRATIO,
            WEBSITES.GETOOKA
          ].includes(process.env.NEXT_PUBLIC_APP) ? (
            <AddToCartWidget
              data={productDetails}
              page="Product Details Page"
              maxQuantity={productMaxQuantity} // Same key for bundles and normal products
              showSoldOut
            />
          ) : null}

          {DTCproduct && unifiedMenu === 1 && showTag ? (
            <div className="asap mb-2">
              <i className="icon-fast-time " />
              <span> ASAP</span>
            </div>
          ) : null}

          {![
            WEBSITES.KOAN,
            WEBSITES.DRINKCANN,
            WEBSITES.WUNDER,
            WEBSITES.BEED,
            WEBSITES.CANNADIPSTHC,
            WEBSITES.POTLI,
            WEBSITES.ALTLIFE,
            WEBSITES.HELLOAGAIN,
            WEBSITES.VETCBD,
            // WEBSITES.HUMBOLDTFAMILYFARMS,
            WEBSITES.MAISONBLOOM,
            WEBSITES.BOASTCANNABIS,
            WEBSITES.RIGHTRATIO,
            WEBSITES.GETOOKA
          ].includes(process.env.NEXT_PUBLIC_APP) ? (
            <div className="wishlist-sec">
              {isWishlistApplicable ? (
                <FavouritesIcon
                  data={productDetails}
                  isMostPopularProduct={isMostPopularProduct}
                  isFeaturedProduct={isFeaturedProduct}
                  type='pdp'
                />
              ) : null}
              <div
                className={`relative d-flex justify-content-${quantityInCart > 0 || showLoader || (DTCproduct && !WEBSITES.BENTO) ? 'between' : 'center'
                  } align-items-center add-cart-inner ${!productPresent ? 'disable-add-cart' : ''}`}
                onClick={quantityInCart === 0 ? () => addToCart(true) : undefined}
                test-id="#pdp_add_to_cart"
              >
                <div className="wriggle-trigger" onClick={animateButtons} />

                <div className="p-left-sec">
                  <i className="icon-cart-outline-2 mr-3 l-icon bold" />

                  <span className={`bold ${DTCproduct ? 'semi-bold' : ''} pp-text`}> Add to cart</span>
                </div>

                {quantityInCart > 0 || showLoader ? (
                  <div className={`d-flex p-right-sec align-items-center ${animating ? 'is-animating' : ''}`}>
                    <button
                      id="pdp_subtract_icon"
                      test-id="#pdp_subtract_icon"
                      type="button"
                      className="btn"
                      onClick={updateCartItem}
                      disabled={showLoader || !quantityInCart || updatingQuantity}
                    >
                      <i className="icon-minus-circle" />
                    </button>

                    <div test-id="#pdp_product_quantity" className="count relative">
                      {quantity}
                      {showLoader ? <InlineLoader className={'a-loader new-loadx'} /> : null}
                    </div>

                    <button
                      test-id="#pdp_plus_icon"
                      type="button"
                      className="btn plus-btn"
                      onClick={addToCart}
                      disabled={
                        showLoader || !productPresent || isPreDealPresentInCart || isMaxedOutInCart || limitExceeded || updatingQuantity
                      }
                    >
                      <i className="icon-plus-circle" />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {!DTCproduct && !is_happy_hours_enable ? <DeliveryNote /> : null}
        </div>
      </div>

      <div className="">
        {ALLOW_PRODUCT_COMPARE && !bundleId ? (
          <div className="comp-feature mt-0 mt-lg-4">
            <div>
              {productDetails?.product_id && !productDetails?.bundle_id ? (
                <div className="custom-checkbox d-flex justify-content-between align-items-center">
                  <span>
                    <input
                      type="checkbox"
                      id="add-to-compare"
                      checked={compare && compare.productList.some(e => e === masterProductId)}
                      name="saveCard"
                      onChange={handleCompareCheck}
                    />
                    <label className="compare-check" htmlFor="add-to-compare">
                      Add to compare
                      {/* <i className="icon-info ml-2  " /> */}
                    </label>
                  </span>
                  {compare?.productList?.length ? (
                    <a onClick={openCompareClick} className="compare-link">
                      Compare
                    </a>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {DTCproduct ? (
          <>
            <DangerousHTML
              className="mt-4 maven-description-color pdp-description"
              html={bundleDescription || productDescription || ''}
            />

            <Prop65 className="mt-4 drinkcann-prop-65-top kikoko-prop-65-top plus-products-prop-65-top" />
          </>
        ) : (
          <div className="mt-4">
            {/* <SeeMore
              text={bundleDescription || productDescription || ''}
              customElementName="grassdoor-product-description"
              height={74}
            /> */}
            <div className="see-more-content">
              <div className={` default-product-description`}>
                <DangerousHTML html={bundleDescription || productDescription || ''} className="font-medium cms-description" />
              </div>
            </div>

            {terpenes?.length ? <TerpenesProfile terpenes={terpenes} /> : null}

            {primaryBrandDetails?.name && primaryBrandDetails?.description ? (
              <div className="about-brand mt-3">
                {primaryBrandDetails?.logo ? (
                  <>
                    <h6>About the Brand</h6>
                    <img src={primaryBrandDetails.logo} alt={primaryBrandDetails.name} />
                  </>
                ) : null}

                <SeeMore text={primaryBrandDetails.description} customElementName="default-brand-description" height={100} />
              </div>
            ) : null}
            {productDetails?.all_full_shop_category_name === "Vapes" ? <>
              <div className="disclaimer-content mb-4 mt-3">
                <b>Disclaimer:</b> 
                <ul className="list-disc list-outside pl-7">
                  <li className="pl-[6px]">An empty integrated cannabis vaporizer shall be properly disposed of as a hazardous waste at a household hazardous waste collection facility or other approved facility.</li>
                  <li className="pl-[6px]">A spent cannabis cartridge shall be properly disposed of as hazardous waste at a household hazardous waste collection facility or other approved facility.</li>
                </ul> 
              </div>
            </> : null}
            <Prop65 className="mt-3 " />
          </div>
        )}
      </div >
    </div >
  );
};

export default ProductContent;
